import React from "react";
import { VehicleAudit } from "../../../../types/dsv/audit";
import { GenericDataGrid } from "../../../base/dataGrid";
import { useDsvHomeContext } from "../context";
import { columns } from "./columns";

type MappedRow = {
    id: number
    auditor: string
    scanCount: number
    completedDate: Date
    deleteId: number
}

function VehicleAuditsDataGrid(): JSX.Element {
    const { vehicleAudits, deleteVehicleAudit } = useDsvHomeContext();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        if (!vehicleAudits) {
            return;
        }

        setMappedRows(vehicleAudits.map((value: VehicleAudit): MappedRow => {
            return {
                id: value.vehicleAuditId,
                auditor: value.auditorUserName,
                scanCount: value.scanCount,
                completedDate: value.completedStamp,
                deleteId: value.vehicleAuditId
            };
        }));
    }, [vehicleAudits]);

    return (
        <GenericDataGrid
            columns={columns({ deleteVehicleAudit })}
            initialStatePagination={{
                page: 0,
                pageSize: 25
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            rows={mappedRows}
            />
    );
}

export { VehicleAuditsDataGrid }