import { Box } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnVisibilityModel, GridPaginationModel, GridRowHeightParams, GridRowHeightReturnValue, GridSlotsComponent, GridSortingInitialState } from "@mui/x-data-grid";
import { GridApiCommunity, GridSlotsComponentsProps } from "@mui/x-data-grid/internals";

type GenericDataGridProps = {
    apiRef?: React.MutableRefObject<GridApiCommunity>
    columns: GridColDef[]
    columnVisibilityModel?: GridColumnVisibilityModel
    getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue
    initialStatePagination?: Partial<GridPaginationModel>
    initialStateSorting?: GridSortingInitialState
    onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel, details: GridCallbackDetails) => void | undefined
    pageSizeOptions?: number[]
    ref?: React.LegacyRef<HTMLDivElement>
    rows: any[]
    rowHeight?: number
    slotProps?: Partial<GridSlotsComponentsProps>
    slots?: Partial<GridSlotsComponent>
}

function GenericDataGrid(props: GenericDataGridProps): JSX.Element {
    return (
        <Box
            sx={{
              flex: 1,
              overflow: "auto"  
            }}
            >
            <DataGrid
                apiRef={props.apiRef}
                autoHeight={true}
                columns={props.columns}
                columnVisibilityModel={props.columnVisibilityModel}
                getRowHeight={props.getRowHeight}
                initialState={{
                    columns: {
                        columnVisibilityModel: props.columnVisibilityModel
                    },
                    pagination: {
                        paginationModel: props.initialStatePagination
                    },
                    sorting: props.initialStateSorting
                }}
                loading={props.rows.length === 0}
                onColumnVisibilityModelChange={props.onColumnVisibilityModelChange}
                pageSizeOptions={props.pageSizeOptions}
                ref={props.ref}
                rows={props.rows}
                rowHeight={props.rowHeight}
                slotProps={props.slotProps}
                slots={props.slots}
                />
        </Box>
        
    );
}

export { GenericDataGrid }
export type { GenericDataGridProps }